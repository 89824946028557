import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { DatePicker } from '../ui/DatePicker';
import { TimePicker } from '../ui/TimePicker';
import { Button } from '../ui/Buttons/Button';
import { manageError } from '../assets/utils/errors';
import Yup from 'i18n/validation';
import { EmailingJobModel } from '@assets/models/scheduler/Job.model';
import {
  UpdateEmailingJobScheduleDateRequest,
} from '../../../../lambdas/shared/requests/emailingJobs/UpdateEmailingJobScheduleDate.request.ts';
import { useTranslation } from 'react-i18next';
import { onCall } from '../Services/firebase.service';
import { Timestamp } from 'firebase/firestore';
import { useInstances } from 'react-ioc';
import { EmailingJobsStore } from '../Stores/EmailingJobs.store';
import { combineDateTimeToTimestamp } from './../Utils/convertDate.utils';

interface Props {
  job: EmailingJobModel;
  onClose?: () => void;
}

interface FormValues {
  date: Date;
  time: Date;
}

export const RescheduleOnboardingMailDialog: React.FC<Props> = ({ job, onClose }) => {
  const [emailingJobsStore] = useInstances(EmailingJobsStore);

  const { t } = useTranslation('beneficiaries');

  const jobDate: Date = useMemo(() => {
    if ((job.scheduledDate as any) instanceof Timestamp) {
      return new Date((job.scheduledDate as unknown as Timestamp).seconds * 1000);
    }
    return new Date(job.scheduledDate);
  }, [job.scheduledDate])

  const initialValues: FormValues = useMemo(() => ({
    date: jobDate,
    time: jobDate,
  }), [jobDate]);

  const handleSubmit: (values: FormValues, { setSubmitting }: {
    setSubmitting: (isSubmitting: boolean) => void
  }) => Promise<void> = useCallback(async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      const newDate: number = combineDateTimeToTimestamp(values.date, values.time);
      const request: UpdateEmailingJobScheduleDateRequest = {
        emailingJobId: job.uid,
        scheduledDate: newDate,
        agencyId: job.agencyId,
      };

      await onCall('EMAILING_JOBS-updateEmailingJobScheduleDate_onCall', request);
      await emailingJobsStore.getCreatedEmailingJobs(job.agencyId);

    } catch (error) {
      manageError(error);
    } finally {
      setSubmitting(false);
      onClose();
    }
  }, [job, combineDateTimeToTimestamp]);

  const validationSchema = useMemo(() =>
      Yup.object().shape({
        date: Yup.date()
          .nullable()
          .required('Cette information est obligatoire')
          .min(new Date(), 'La date doit être supérieure à aujourd\'hui')
          .typeError('Format de date invalide'),
        time: Yup.date()
          .nullable()
          .required('Cette information est obligatoire')
          .typeError('Format d\'heure invalide'),
      })
    , []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const message = t('RescheduleOnboardingMailDialog.message', { count: job.beneficiariesIds.length });

  return (
    <div className={'rounded-opnXL p-8 mx-auto'}>
      <p className={'mb-6'}>{message}</p>
      <form onSubmit={formik.handleSubmit} noValidate>
        <fieldset className={'flex flex-col gap-4 m-0 p-0'}>
          <div className={'flex gap-4'}>
            <DatePicker id={'date'} name={'date'} formik={formik} label={t('invitationSendDate')} required/>
            <TimePicker id={'time'} name={'time'} formik={formik} label={t('invitationSendTime')} required/>
          </div>
          <div className={'flex justify-end gap-4 mt-4'}>
            <Button variant={'outlined'} onClick={onClose}>{t('cancel')}</Button>
            <Button type={'submit'} loading={formik.isSubmitting}
                    disabled={formik.isSubmitting}>{t('validate')}</Button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};